<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'App',
  // Component logic goes here
};
</script>

<style>
* {
  padding: 0;
  margin: 0;
}

body {
  background-color: #000000;
  color: #ffffff;
  font-family: Arial, sans-serif;
}
</style>
